import React, { useEffect, useState, useContext } from 'react'
import { Button } from '@material-ui/core'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AuthContext from '@/product/context/auth/authContext'
import PayContext from '@/product/context/payment/payContext'
import CommonContext from '@/product/context/common/commonContext'
import AlertContext from '@/product/context/alert/alertContext'
import StripeCardContext from '@/product/context/stripe/card/cardContext'
import StripeCustomerContext from '@/product/context/stripe/customer/customerContext'

const ReinstateModal = () => {
    const [paymentProcessing, setPaymentProcessing] = useState(false)
    const [staticContent, setStaticContent] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)

    const payContext = useContext(PayContext)
    const authContext = useContext(AuthContext)
    const commonContext = useContext(CommonContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { user } = authContext
    const { setAlert } = alertContext
    const { static_page, getStaticPage } = commonContext
    const { reinstateModal, setReinstateModal } = customCommonContext
    const {
        payCartStripe,
        responseStatus: responseStatusPayment,
        clearResponse: clearResponsePayment,
    } = payContext
    const { customer_details, getStripeCustomer } = useContext(StripeCustomerContext)
    const {
        get_all_payment_method_card_details,
        getAllStripePaymentMethodCards,
        responseStatus: responseStatusCard,
        clearResponse: clearResponseCard,
    } = useContext(StripeCardContext)

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.status === 'success' && responseStatusPayment.type === 1) {
                if (responseStatusPayment?.data?.pay_type === 'user_reinstate_fee') {
                    setReinstateModal(false)
                }
            }
            clearResponsePayment()
            setPaymentProcessing(false)
        }
    }, [responseStatusPayment])

    useEffect(() => {
        getStaticPage({ id: 'reinstate_terms' })
    }, [])

    useEffect(() => {
        if (static_page?.record?.name === 'reinstate_terms') {
            setStaticContent(static_page.record)
        }
    }, [static_page])

    const payReinstateFee = () => {
        const formData = {
            payment_method: 'online',
            payment_type: 'card',
            pay_type: 'user_reinstate_fee',
            amount: 25,
            paymentInfo: {
                source:
                    customer_details?.invoice_settings?.default_payment_method ||
                    get_all_payment_method_card_details?.records[0].id,
                customer: user.stripe_id,
                description: 'BOXBIDS User Reinstate Fee',
            },
            payment_object: {
                id: '',
                appointmentTime: null,
                invoice_id: 0,
            },
        }

        setPaymentProcessing(true)
        setConfirmModal(false)
        setAlert('Payment Processing, Please do not Hit Refresh!', 'info')
        payCartStripe(formData)
    }

    return (
        <>
            <CustomDialog
                title={`Activate Account`}
                open={reinstateModal}
                function={() => {
                    setReinstateModal(false)
                }}
                className="modalView"
            >
                <>
                    <div className="staticPages ck-content">
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-start">
                                {staticContent?.content_type === 'file' ? (
                                    <object>
                                        <embed
                                            src={`${global.site_url}/uploads/static_files/${staticContent?.file_path}`}
                                            type="application/pdf"
                                        ></embed>
                                    </object>
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: staticContent?.content,
                                        }}
                                    ></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <PrimaryButton
                            id="make_payment"
                            label="Make Payment"
                            btnSize="small"
                            disabled={paymentProcessing}
                            onClick={() => {
                                setConfirmModal(true)
                            }}
                        />
                    </div>
                </>
            </CustomDialog>
            <CustomDialog
                title={`Confirm Action`}
                open={confirmModal}
                function={() => {
                    setConfirmModal(false)
                }}
                className="modalView"
            >
                <div>
                    <h5>{`You are about to pay $25 to Reinstate your account`}</h5>
                    <h5>{`Please confirm if you want to continue with this action`}</h5>
                    <div className="actionWrapper">
                        <Button id="reinstate_cancel" onClick={() => setConfirmModal(false)}>
                            {`Cancel`}
                        </Button>
                        <PrimaryButton
                            id="reinstate_confirm"
                            onClick={() => {
                                payReinstateFee()
                            }}
                            label={`Submit`}
                        />
                    </div>
                </div>
            </CustomDialog>
        </>
    )
}

export default ReinstateModal
