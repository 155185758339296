import React, { useContext, useEffect, useState } from 'react'
import RegisterComponent from '../../product/components/species/dynamic/common/Register/RegisterComponent'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import ProductCommonContext from '../../product/context/common/commonContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CommonContext from '../../custom/context/common/commonContext'
import AlertContext from '../../product/context/alert/alertContext'
// import AuthContext from '../../custom/context/auth/authContext'
import RadioBox from '../../product/components/atoms/RadioBox'
import CheckBox from '../../product/components/atoms/CheckBox'
import { useFormik, connect, Form, Formik } from 'formik'
import { FilterFramesOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Button, Fade } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '../../utils'
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Document } from 'react-pdf'
import { stubFalse } from 'lodash'
import './Registration.css'
import * as Yup from 'yup'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import SEO from '@/utils/SEO'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import UserContext from '@/product/context/user/userContext'
import { decrypt } from '@/product/common/cryptos'
import AirlineSeatReclineExtraOutlined from '@material-ui/icons/AirlineSeatReclineExtraOutlined'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const Registration = (props) => {
    // const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const userContext = useContext(UserContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { updateProfile, updatePreference, responseStatus: reponseStatusUser } = userContext
    const { setAlert } = alertContext
    // const { register, responseStatus, clearResponse } = authContext
    const { signupModal, setSignupModal, setLoginModal } = customCommonContext
    const [reload, setReload] = useState(false)

    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const dynamicContext = useContext(DynamicContext)

    const {
        getAllDynamicArray,
        dynamicValue,
        getDynamicTableValues,
        dynamicTableValue,
        dynamicTableAction,
        responseStatus: reponseStatusDynamic,
        clearResponse: clearResponseDynamic,
    } = dynamicContext
    const [toggleSignup, setToggleSignup] = useState(false)
    const [updatedValues, setUpdatedValues] = useState({})
    const [customValidation, setCustomValidation] = useState([])
    const [customValidationSeller, setCustomValidationSeller] = useState([])

    const [customInitialSeller, setCustomInitialSeller] = useState([])
    const [active, setActive] = useState([])
    const [customInitialBuyer, setCustomInitialBuyer] = useState([])
    const [fieldValue, setFieldValue] = useState([])
    const [arrayValue, setArrayValue] = useState([])
    const [sellerValue, setSellerValue] = useState([])
    const [updating, setUpdating] = useState(false)
    const { configFeatures } = commonContext
    const { static_page, getStaticPage } = productCommonContext
    let [termsLink, setTermsLink] = useState([])
    let randomVariable = Math.random()

    // YUP Validation array to ensure all required fields are given values
    const SubmitBtnLabel = 'REGISTER'

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    // Set Initial values

    useEffect(() => {
        setActive([])
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forOrganization: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_organization',
                    },
                },
            },
            'forOrganizationSignup',
        )
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forBuyer: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_buyer',
                    },
                },
            },
            'forBuyerSignup',
        )
    }, [])

    useEffect(() => {
        if (dynamicValue.from === 'forOrganizationSignup') {
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                dynamicValue.records.map((item) => {
                    item.view_on
                        ? item.view_on.includes('7')
                            ? dynamicValueAssign.push(item)
                            : {}
                        : {}
                })
                setSellerValue(dynamicValueAssign)
            }
        }
        if (dynamicValue.from === 'forBuyerSignup') {
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                dynamicValue.records.map((item) => {
                    if (item.view_page === '3' && item.type === 'phone') {
                        item.countryCodeEditable = false
                    }
                    if (item.view_on && item.view_on.includes('7')) {
                        if (item.name == 'country') {
                            item.disabled = true
                        } else {
                            dynamicValueAssign.push(item)
                        }
                    }
                })
                dynamicValueAssign.push({
                    label: (
                        <>
                            <p className="adultchk">
                                I agree to the{' '}
                                <Link to="/terms" target="_blank">
                                    Terms & Conditions
                                </Link>
                            </p>
                        </>
                    ),
                    name: 'termcheck',
                    type: 'checkbox',
                    placeholder: '',
                    class: 'col-12 auth-links-wrap',
                })
                setFieldValue(dynamicValueAssign)
            }
        }
    }, [dynamicValue, updating])

    useEffect(() => {
        setActive([])
        setToggleSignup(false)
        let customValidationTemp = {}
        let customInitial = {
            phone_verified: 0,
            termCheck: 0,
            status: 'email_unverified',
            admin: 0,
            country: 'US',
            is_preregister_email_sent: 1,
        }
        let customOrgInitial = {
            phone_verified: 0,
            termCheck: 0,
            admin: 1,
            organization: 1,
            status: 'email_unverified',
            emp_type: 'seller',
        }
        customValidationTemp = {
            email: Yup.string()
                .email('Invalid Email Format')
                .max(250, 'Maximum 250 Characters')
                .required('Required'),
            password: Yup.string().min(8, 'Minimum 8 Characters').required('Required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], 'Password does not match')
                .required('Required'),
            first_name: Yup.string()
                .matches(/^[aA-zZ|'\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(30, 'Maximum 30 characters')
                .required('Required!'),
            last_name: Yup.string()
                .matches(/^[aA-zZ|'.\s]+$/, 'Only alphabets are allowed for this field ')
                .min(1, 'Mininum 1 characters')
                .max(30, 'Maximum 30 characters')
                .required('Required!'),
            phone: Yup.string()
                .trim()
                .nullable()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .required('Required!'),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, 'Positive Numbers only')
                .min(5, 'Minimum 5 characters')
                .max(6, 'Maximum 6 characters')
                .required('Required'),
            city: Yup.string()
                .trim()
                .matches(/^[aA-zZ.\s]+$/, 'Only alphabets are allowed for this field ')
                .required('Required'),
            termcheck: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
            other_detail: Yup.string().when('how_did_you_hear_about_us', {
                is: (type) => type === 'Other',
                then: Yup.string().required('Required!'),
                otherwise: Yup.string(),
            }),
        }
        setCustomInitialSeller(customOrgInitial)
        setCustomInitialBuyer(customInitial)
        setCustomValidation(customValidationTemp)
    }, [])

    const additionalFieldUpdate = (data) => {
        setUpdatedValues(data)
    }

    // Mapped over for individual registration fields

    useEffect(() => {
        if (reponseStatusDynamic) {
            if (reponseStatusDynamic.from === 'dynamicTableAction') {
                if (reponseStatusDynamic.status === 'success') {
                    setActive([])
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [reponseStatusDynamic])

    const onSuccess = (data) => {
        setSignupModal(false)
    }

    const registerModalChange = () => {
        setSignupModal(false)
        setLoginModal(true)
    }

    useEffect(() => {
        console.log(signupModal, 'popupissue')
    }, [signupModal])

    return (
        <>
            <CustomDialog
                className="productVwDialog"
                function={() => setSignupModal(false)}
                open={signupModal}
            >
                <RegistrationLayout>
                    <div className="regContainer container">
                        <RegisterComponent
                            fieldValue={fieldValue}
                            customInitial={customInitialBuyer}
                            customValidation={customValidation}
                            additionalFieldUpdate={additionalFieldUpdate}
                            SubmitBtnLabel={SubmitBtnLabel}
                            phoneVerify={true}
                            autoRegisterAfterVerify={true}
                            onSuccess={onSuccess}
                        />
                    </div>
                </RegistrationLayout>

                <p className="forPass">
                    Have an account ?
                    <Button onClick={registerModalChange} className="aPass">
                        Login
                    </Button>
                </p>
            </CustomDialog>
        </>
    )
}

export default Registration
