import React, { useContext, useEffect, useState } from 'react'
import RegisterComponent from '../../product/components/species/dynamic/common/Register/RegisterComponent'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import ProductCommonContext from '../../product/context/common/commonContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CommonContext from '../../custom/context/common/commonContext'
import AlertContext from '../../product/context/alert/alertContext'
// import AuthContext from '../../custom/context/auth/authContext'
import RadioBox from '../../product/components/atoms/RadioBox'
import CheckBox from '../../product/components/atoms/CheckBox'
import { useFormik, connect, Form, Formik } from 'formik'
import { FilterFramesOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Button, Fade } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '../../utils'
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Document } from 'react-pdf'
import { stubFalse } from 'lodash'
import './Registration.css'
import * as Yup from 'yup'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import SEO from '@/utils/SEO'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import Loaders from '@/custom/components/molecules/Loaders'
import UserContext from '@/product/context/user/userContext'
import { decrypt } from '@/product/common/cryptos'
import Registration from './index'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const RegistrationDynamic = (props) => {
    // const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const userContext = useContext(UserContext)
    const customCommonContext = useContext(CustomCommonContext)
    const {
        updateProfile,
        updatePreference,
        responseStatus: reponseStatusUser,
        resetUserProfile,
        clearResponse: clearResponseUser,
    } = userContext
    const { setAlert } = alertContext
    // const { register, responseStatus, clearResponse } = authContext
    // const { signupModal, setSignupModal, setLoginModal } = customCommonContext
    const [reload, setReload] = useState(false)

    const commonContext = useContext(CommonContext)
    const [loading, setLoading] = useState(false)
    const productCommonContext = useContext(ProductCommonContext)
    const dynamicContext = useContext(DynamicContext)

    const {
        getAllDynamicArray,
        dynamicValue,
        getDynamicTableValues,
        dynamicTableValue,
        dynamicTableAction,
        responseStatus: reponseStatusDynamic,
        clearResponse: clearResponseDynamic,
    } = dynamicContext
    const [toggleSignup, setToggleSignup] = useState(false)
    const [updatedValues, setUpdatedValues] = useState({})
    const [customValidation, setCustomValidation] = useState([])
    const [customValidationSeller, setCustomValidationSeller] = useState([])

    const [customInitialSeller, setCustomInitialSeller] = useState([])
    const [active, setActive] = useState([])
    const [customInitialBuyer, setCustomInitialBuyer] = useState([])
    const [fieldValue, setFieldValue] = useState([])
    const [arrayValue, setArrayValue] = useState([])
    const [sellerValue, setSellerValue] = useState([])
    const [updating, setUpdating] = useState(false)
    const { configFeatures } = commonContext
    const { static_page, getStaticPage } = productCommonContext
    let [termsLink, setTermsLink] = useState([])
    let token = props?.match?.params?.token ? props.match.params.token : null
    const [signupModal, setSignupModal] = useState(false)
    let randomVariable = Math.random()

    // YUP Validation array to ensure all required fields are given values
    const SubmitBtnLabel = 'REGISTER'

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
    }, [])

    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    // Set Initial values

    useEffect(() => {
        setActive([])
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forBuyer: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_buyer',
                    },
                },
            },
            'pre_register',
        )
    }, [])

    useEffect(() => {
        if (token) {
            // setSignupModal(true)
            setLoading(true)
            const userDetails = decrypt(token)
            console.log(userDetails, 'uiii')
            getDynamicTableValues(
                {
                    page: 1,
                    limit: 2,
                    count: 1,
                    orderby: '',
                    filters: {
                        email: {
                            value: userDetails,
                            type: 'in',
                            field: 'u.email',
                        },
                    },
                    table: 'users',
                    customTable: '',
                },
                'user_details',
            )
        }
    }, [token])

    useEffect(() => {
        if (dynamicTableValue && dynamicTableValue.from === 'user_details') {
            console.log(dynamicTableValue, 'dynn')
            if (dynamicTableValue.records.length) {
                const tableValues = dynamicTableValue.records
                if (tableValues[0].phone && tableValues[0].phone !== '') {
                    handleRedirectInternal(history, '')
                } else {
                    setActive(tableValues)
                }
            } else {
                setActive([])
            }
        }
    }, [dynamicTableValue])

    useEffect(() => {
        if (reponseStatusUser) {
            if (reponseStatusUser.from === 'resetUserProfile') {
                if (reponseStatusUser.status === 'success') {
                    setActive([])
                    setSignupModal(false)
                    setAlert('Successfully Registered', 'success')
                    handleRedirectInternal(history, '')
                } else {
                    setAlert('Registration failed', 'error')
                }
            }
        }
        clearResponseUser()
    }, [reponseStatusUser])

    useEffect(() => {
        if (dynamicValue.from === 'pre_register') {
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                dynamicValue.records.map((item) => {
                    if (item.view_on && item.view_on.includes('7')) {
                        if (item.name == 'country') {
                            item.disabled = true
                        } else {
                            dynamicValueAssign.push(item)
                        }
                    }
                })
                dynamicValueAssign.push({
                    label: (
                        <>
                            <p className="adultchk">
                                I agree to the{' '}
                                <Link to="/terms" target="_blank">
                                    Terms & Conditions
                                </Link>
                            </p>
                        </>
                    ),
                    name: 'termcheck',
                    type: 'checkbox',
                    placeholder: '',
                    class: 'col-12 auth-links-wrap',
                })
                setFieldValue(dynamicValueAssign)
            }
        }
    }, [dynamicValue, updating])

    useEffect(() => {
        console.log(active, token, 'active details')
        if (active.length && token && fieldValue.length) {
            console.log('is it there')
            let allArray = [...fieldValue]
            allArray.map((data) => {
                if (data.name === 'email') {
                    data.initialDefault = active[0].email
                    data.disabled = true
                }

                if (data.name === 'first_name') {
                    data.initialDefault = active[0].first_name
                    data.disabled = true
                }
                if (data.name === 'last_name') {
                    data.initialDefault = active[0].last_name
                    data.disabled = true
                }
                if (data.name === 'city') {
                    data.initialDefault = active[0].city
                    data.disabled = true
                }
                if (data.name === 'state') {
                    data.initialDefault = active[0].state
                    data.disabled = true
                }
                if (data.name === 'address1') {
                    data.initialDefault = active[0].address1
                    data.disabled = true
                }
                if (data.name === 'zip') {
                    data.initialDefault = active[0].zip
                    data.disabled = true
                }
            })
            setArrayValue(allArray)
            setCustomInitialBuyer((prevData) => ({
                ...prevData,
                id: active[0].id,
            }))

            setSignupModal(true)
            setLoading(false)
        }
    }, [active, fieldValue])

    useEffect(() => {
        setActive([])
        setToggleSignup(false)
        let customValidationTemp = {}
        let customInitial = {
            phone_verified: 0,
            termCheck: 0,
            status: 'active',
            admin: 0,
            country: 'US',
        }
        let customOrgInitial = {
            phone_verified: 0,
            termCheck: 0,
            admin: 1,
            organization: 1,
            status: 'active',
            emp_type: 'seller',
        }
        customValidationTemp = {
            email: Yup.string()
                .email('Invalid Email Format')
                .max(250, 'Maximum 250 Characters')
                .required('Required'),
            password: Yup.string().min(8, 'Minimum 8 Characters').required('Required'),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], 'Password does not match')
                .required('Required'),
            first_name: Yup.string()
                .matches(/^[aA-zZ|'\s]+$/, 'Only alphabets are allowed for this field ')
                .min(2, 'Mininum 2 characters')
                .max(30, 'Maximum 30 characters')
                .required('Required!'),
            last_name: Yup.string()
                .matches(/^[aA-zZ|'.\s]+$/, 'Only alphabets are allowed for this field ')
                .min(1, 'Mininum 1 characters')
                .max(30, 'Maximum 30 characters')
                .required('Required!'),
            phone: Yup.string()
                .trim()
                .nullable()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .required('Required!'),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, 'Positive Numbers only')
                .min(5, 'Minimum 5 characters')
                .max(6, 'Maximum 6 characters')
                .required('Required'),
            city: Yup.string()
                .trim()
                .matches(/^[aA-zZ.\s]+$/, 'Only alphabets are allowed for this field ')
                .required('Required'),
            termcheck: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
            other_detail: Yup.string().when('how_did_you_hear_about_us', {
                is: (type) => type === 'Other',
                then: Yup.string().required('Required!'),
                otherwise: Yup.string(),
            }),
        }
        setCustomInitialSeller(customOrgInitial)
        setCustomInitialBuyer(customInitial)
        setCustomValidation(customValidationTemp)
    }, [])

    const additionalFieldUpdate = (data) => {
        setUpdatedValues(data)
    }

    const userExistCheck = () => {
        let finalVal = { ...updatedValues }
        finalVal.user_token = token
        resetUserProfile(finalVal)
    }

    const submitFunction = () => {
        let finalVal = { ...updatedValues }
        finalVal.table = 'users'
        delete finalVal.password
        delete finalVal.confirm_password
        dynamicTableAction(finalVal)
    }

    // Mapped over for individual registration fields

    useEffect(() => {
        if (reponseStatusDynamic) {
            if (reponseStatusDynamic.from === 'dynamicTableAction') {
                if (reponseStatusDynamic.status === 'success') {
                    setActive([])
                    setSignupModal(false)
                    setAlert('Successfully Registered', 'success')
                    handleRedirectInternal(history, '')
                } else {
                    setAlert('Registration failed', 'error')
                }
            }
        }
        clearResponseDynamic()
    }, [reponseStatusDynamic])

    const onSuccess = (data) => {
        setSignupModal(false)
    }

    useEffect(() => {
        console.log(signupModal, 'popupissue')
    }, [signupModal])

    return (
        <>
            <Loaders name="home" isLoading={loading} />
            <CustomDialog
                className="productVwDialog"
                function={() => setSignupModal(false)}
                open={signupModal}
                disableBackdropClick
            >
                <RegistrationLayout>
                    <div className="regContainer container">
                        <RegisterComponent
                            fieldValue={arrayValue}
                            customInitial={customInitialBuyer}
                            customValidation={customValidation}
                            additionalFieldUpdate={additionalFieldUpdate}
                            SubmitBtnLabel={SubmitBtnLabel}
                            phoneVerify={true}
                            onSuccess={onSuccess}
                            submitFunction={userExistCheck}
                            autoRegisterAfterVerify={true}
                        />
                    </div>
                </RegistrationLayout>
            </CustomDialog>
        </>
    )
}

export default RegistrationDynamic
