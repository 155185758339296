import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
    LOGIN_MODAL,
    SIGNUP_MODAL,
    FORGOT_PASSWORD_MODAL,
    SOCKET_MODAL,
    REINSTATE_MODAL,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                ...action.payload,
            }
        case SWITCH_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case LOGIN_MODAL:
            return {
                ...state,
                loginModal: !state.loginModal,
            }
        case REINSTATE_MODAL:
            return {
                ...state,
                reinstateModal: action.payload,
            }
        case SIGNUP_MODAL:
            return {
                ...state,
                signupModal: !state.signupModal,
            }
        case FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                forgetPasswordModal: !state.forgetPasswordModal,
            }
        case SOCKET_MODAL:
            return {
                ...state,
                socketModal: !state.socketModal,
            }
        default:
            return state
    }
}
